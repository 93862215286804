/* Imported Styles */
@import 'dropdownStyles.css';
@import 'buttonStyle.css';
@import 'tableStyle.css';
@import 'responsiveTitle.css';
@import 'paginationStyle.css';
@import 'siderMenuStyle.css';
@import 'modalStyle.css';
@import 'switchStyle.css';
@import 'checkStyle.css';
/* Base styles */
body {
    font-family: 'Segoe UI';
    margin: 0;
    background-color: #3c174f;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
  }


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Component Styles */
.cardsBody, .container, .header, .display-message, .position, .embed-report, .controls, .footer, .button, .login-container, .admin-layout, .client-layout, .admin-header, .client-header, .content-layout, .title, .menu, .site-layout-background, .not-implemented, .admin-footer, .client-footer, .report-container {
  box-sizing: border-box;
}

.cardsBody {
  margin: 0;
  padding: 10px;
  min-height: 100vh;
  background-image: url('/src/assets/images/TSPlus-Background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  background: #3476ae;
  border: 1px solid #707070;
  color: #ffffff;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  padding: 13px 13px 13px 36px;
  text-align: left;
}

.display-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  height: 30px;
  margin-top: 8px;
}

.position {
  margin-top: 40vh;
}

.embed-report, .controls {
  margin-top: 20px;
  text-align: center;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f8fa;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  height: 42px;
  width: 100%;
}

.footer * {
  padding: 0 3px;
}

.footer-icon {
  border-radius: 50%;
  height: 22px;
  vertical-align: middle;
}

.footer a {
  color: #3a3a3a;
  text-decoration: underline;
}

.custom-menu-item:hover {
    background-color: #d67632;
  }
  
  .ant-btn.button:hover {
    background-color: #d67632 !important;
  }

.login-container {
  text-align: center;
  width: 400px;
  position: relative;
  padding: 20px 35px;
  background: #5f2d78;
  border-radius: 5px;
}

.admin-layout, .client-layout {
  flex: 1;
  overflow-y: auto;
}

.admin-header, .client-header {
  background: #fff;
  padding: 0 50px;
  box-shadow: 0 2px 8px #f0f1f2;
}

.content-layout {
  padding: 24px;
  margin: 0;
  min-height: 280px;
  background: #fff;
}

.admin-footer, .client-footer {
  text-align: center;
  background: #fff;
  padding: 10px 50px;
}

.menu {
  height: 100%;
  border-right: 0;
}

.not-implemented {
  color: red;
}

/* Responsive Styles */
@media only screen and (max-width: 600px) {
  .admin-header, .admin-footer {
    padding: 0 20px;
  }
  .responsive-title {
    font-size: 8px;
 }
}
 
.report-container {
  height: 75vh;
  margin: 8px auto;
  width: 90%;
}

 .ant-spin-dot-item  {
  background-color: #d67632 !important;
  color: #d67632 !important;
}