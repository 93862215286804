/* Override the background color of the active switch */
.ant-checkbox-checked,
.ant-checkbox-input,
.ant-checkbox-inner {
    background-color: #5f2d78 !important;
}

.ant-checkbox-checked:hover,
.ant-checkbox-input:hover,
.ant-checkbox-inner:hover {
    background-color: #d67632 !important;
}
