/* CustomTableStyles.css */

/* Changing the active page color */
.ant-pagination-item-active .ant-pagination-item-link {
    background-color: #3c174f !important;
}

/* Changing hover styles for other pages for consistency */
.ant-pagination-item {
    border-color: #d67632 !important;
    background-color: #3c174f !important;
}

.ant-pagination-prev,
.ant-pagination-next {
    border-color: #d67632 !important;
    background-color: #ffffff !important;
}

.ant-pagination-item .ant-pagination-item-link {
    color: #ffffff !important;
}

/* This changes the arrow color*/
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    color: #d67632 !important;
}

/* Change text color of the pagination items */
.ant-pagination-item a {
    color: #d67632 !important;
}

/* Change text color of the prev and next buttons */
.ant-pagination-prev a, 
.ant-pagination-next a {
    color: #d67632 !important;
}

/* Changing the pagination buttons hover color */
.ant-pagination-prev:hover a, 
.ant-pagination-next:hover a {
    color: #d67632;
}

.ant-pagination-prev:focus .ant-pagination-item-link, 
.ant-pagination-prev:hover .ant-pagination-item-link, 
.ant-pagination-next:focus .ant-pagination-item-link, 
.ant-pagination-next:hover .ant-pagination-item-link, 
.ant-pagination-jump-prev:focus .ant-pagination-item-link, 
.ant-pagination-jump-prev:hover .ant-pagination-item-link, 
.ant-pagination-jump-next:focus .ant-pagination-item-link, 
.ant-pagination-jump-next:hover .ant-pagination-item-link {
    border-color: #d67632;
}