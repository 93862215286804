.ant-modal-footer .ant-btn-default {
    background-color: #5f2d78 !important;
    border-color: 2px solid #d67632 !important;
    color: #ffffff !important;
}

.ant-modal-footer .ant-btn-primary {
    background-color: #5f2d78 !important;
    border-color:  2px solid #d67632 !important;
    color: #ffffff !important;
}

.ant-modal-footer .ant-btn {
    background-color: #5f2d78 !important;
    border-color: 2px solid #d67632 !important;
    color: #ffffff !important;
}

/* .ant-modal-confirm-btns .ant-btn {

    border-color: 2px solid #d67632 !important;
    color: #ffffff !important;
} */

.ant-modal-confirm-btns .ant-btn:nth-child(1) {
    border-color: 2px solid #d67632 !important;
    color: #000000 !important;
}

.ant-modal-confirm-btns .ant-btn:nth-child(2) {
    background-color: #8d6092 !important;
    border-color: 2px solid #cca080 !important;
    color: #ffffff !important;
}

.ant-modal-confirm-btns .ant-btn:nth-child(1):hover {
    background-color: #d67632 !important;
}

.ant-modal-confirm-btns .ant-btn:nth-child(2):hover {
    background-color: #d67632 !important;
}

.ant-modal-confirm-btns .ant-btn:nth-child(1):active {
    background-color: #d67632 !important;
}

.ant-modal-confirm-btns .ant-btn:nth-child(2):active {
    background-color: #d67632 !important;
}
