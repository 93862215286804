.table-row-light {
    background-color: #4B0082;
}

.table-row-dark {
    background-color: #800080;
}

.ant-table-thead > tr > th {
    background-color: #6A0DAD;
    color: #FFF;
    font-weight: bold;
}

.ant-table-tbody > tr > td {
    border-bottom: 1px solid #6A0DAD;
}
