.select-header-container {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    background-color: #3f3f7f;
    color: white;
    padding: 8px 16px;
    font-weight: bold;
}

.select-header-cell {
    padding: 5px;
    text-align: center;
}

.select-item-container {
    background-color: #5f2d78;
    color: #fff;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    width: 100%;
    padding: 8px 16px;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 10px;
    border: none;
    outline: none;
    transition: background-color 0.3s, color 0.3s;
    border: 2px solid white;
}

.select-item-cell {
    padding: 2px;
    text-align: center; /* Center align the text */
}

/* Optional: Style the last cell differently if needed */
/* .select-item-cell:last-child { */
    /* Any special styles for the last cell */
/* } */
