.ant-menu-dark .ant-menu-item:hover {
    background-color: #d67632 !important;
}

.ant-menu-dark .ant-menu-item-selected {
    background-color: #5f2d78 !important;
}

.ant-menu-dark .ant-menu-item, 
.ant-menu-dark .ant-menu-item a {
    color: #ffffff !important;
}

.ant-menu-dark .ant-menu-item:hover, 
.ant-menu-dark .ant-menu-item:hover a {
    color: #ffffff !important;
}